<template>
  <sm-page-loader v-if="childList.isLoading" />
  <div v-else class="editable-list contracts">
    <sm-breadcrumbs class="editable-list__breadcrumbs" :items="breadcrumbs" />
    <sm-datatable
      class="editable-list__datatable contracts__list"
      :caption="tableCaption"
      :headers="tableHeaders"
      :items="childList.data"
    >
      <template v-slot:services="{ row }">
        <table class="contracts-services">
          <tr>
            <th>Услуга</th>
            <th>Количество</th>
            <th>Цена</th>
            <th>Стоимость</th>
          </tr>
          <tr v-for="(item, index) in row.services" :key="'services' + index">
            <td>{{ item.service }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.sum }}</td>
          </tr>
        </table>
      </template>
    </sm-datatable>
  </div>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';

export default {
  name: 'OneCContractsForCustomer',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmDatatable,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'oneCContracts',
      parentName: 'Customer',
      tableCaption: 'Договоры',
      tableHeaders: [
        { text: 'Номер', alias: 'number', order: 'number' },
        { text: 'Дата', alias: 'date', order: 'date' },
        { text: 'Периодичность', alias: 'periodic', order: 'periodic' },
        { text: 'Статус', alias: 'status', order: 'status' },
        { text: 'Партнер', alias: 'partner', order: 'partner' },
        { text: 'Тип', alias: 'type', order: 'type' },
        { text: 'ИНН', alias: 'inn', order: 'inn' },
        { text: 'Услуги', alias: 'services' },
      ],
    };
  },

  computed: {
    ...mapState(['item', 'childList']),

    id() {
      return +this.$route.params.id;
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.item.data?.name}`,
          route: { name: 'CustomerEdit', params: { id: this.id } },
        },
        {
          text: 'Договоры',
        },
      ];
    },
  },

  created() {
    this.getItem({ name: 'Customers', id: this.id });

    this.getChildList({
      name: this.controllerName,
      parentName: this.parentName,
      parentId: this.id,
      params: {
        skip: 0,
        count: 150,
      },
    });
  },

  methods: {
    ...mapActions(['getItem', 'getChildList']),
  },
};
</script>

<style lang="scss">
.editable-list__breadcrumbs {
  margin-bottom: 15px;
}

.contracts__list .datatable__cell {
  &:last-of-type {
    padding: 0;
  }
}

.contracts-services {
  width: 100%;
  height: 100%;
}

.contracts-services th,
.contracts-services td {
  padding: 5px;

  border: 1px solid var(--blue);

  &:first-of-type {
    border-left: none;
  }
  &:last-of-type {
    border-right: none;
  }
}

.contracts-services th {
  border-top: none;
}

.contracts-services td {
  border-bottom: none;
}
</style>
